import { usePage } from './api'
import { useEffect } from 'react'
import parse from 'html-react-parser'
import { Layout } from '../components/layout'

export const About = () => {

    const [ page, getPage ] = usePage(null)

    useEffect(() => {
        getPage("about")
    }, [])

    return(
        <Layout>
            <div className="page about">
            {page && parse(page)}
            </div>
        </Layout>
    )
}