import { useState } from 'react'

export const useEvents = () => {
  const [ events, setEvent ] = useState(null)

  const getEvents = (isList = false) => {
    fetch(process.env.REACT_APP_WP_API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `{
          posts {
            nodes {
              featuredImage {
                node {
                  srcSet(size: MEDIUM_LARGE)
                }
              }
              title
              expirationDate
              postId
              uri
              slug
            }
          }
        }`,
      }),
    })
    .then(res => res.json())
    .then((res) => {
      
      let events = res.data.posts.nodes
      if( events[0]) {
        events.sort((a, b) => parseFloat(a.expirationDate) - parseFloat(b.expirationDate))
        if(!isList) {
          events.length = 3
        }
        setEvent(events)
      } else {
        setEvent(false)
      }
      
    })
  }
  return [ events, getEvents ]
}
