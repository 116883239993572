import { useState } from 'react'

export const useContact = ( defaultContactStatus ) => {
    const [ contactStatus, setContactStatus ] = useState(defaultContactStatus)

    const resetForm = (dispatch = null, defaultState) => {
        setTimeout(function() {
            dispatch && dispatch({ type: 'reset', value: defaultState })
            setContactStatus(null)
        }, 3000)
    }

    const sendMessage = (state) => {
        fetch(`${process.env.REACT_APP_CONTACT}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(state)
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            
            if( data.success === true ) {
                setContactStatus({ success: true })
            } else {
                setContactStatus({ success: false })
            }
        })
    }

    return [ contactStatus, sendMessage, resetForm ]
}