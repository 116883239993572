import { useEffect, useRef } from 'react'

export const Map = () => {    
    
    const mapRef = useRef()

    useEffect(() => {
        let map
        const google = window.google
        const center = { lat: 36.16958, lng: -86.67521 }
        map = new google.maps.Map(mapRef.current, {
            center,
            zoom: 15
        });

        const marker = new google.maps.Marker({
            position: center,
            map
        })
    }, [])

    return(
        <div className="map" ref={mapRef}/>
    )
}