import { Layout } from '../components/layout'
import { Map, ContactForm } from '../components/group'

export const Contact = () => {

    return(
        <Layout>
            <div className="page contact">
                <div className="contactGrid">
                    <ContactForm/>
                    <div className="address">
                        <div className="text">
                            <span>Our Create It events are hosted at:</span>
                            <span>Robin&apos;s Gallery and Custom Frames</span>
                            <span>2602 Lebanon Pike Nashville, TN 37214</span>
                            <span>615-885-1694</span>
                        </div>
                        <Map/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}