import { useReducer, useCallback } from 'react'
import { Textarea, Input } from './'
import { inputReducer } from '../../reducers'
import { useContact } from '../../pages/api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export const ContactForm = () => {

    let defaultState = {
        name: "",
        email: "",
        subject: "",
        message: "",
    }

    const [ contactStatus, sendMessage, resetForm ] = useContact(null)

    const [ state, dispatch ] = useReducer(inputReducer, defaultState)

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async (e, state) => {
        e.preventDefault()
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const captchaToken = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        sendMessage({...state, captchaToken})
    }, [executeRecaptcha, sendMessage]);    

    return (
        contactStatus ?
        contactStatus.success ?
            <div onLoad={resetForm(dispatch, defaultState)} className="contactSuccess">Success! Thanks for the message.</div>
        :
            <div onLoad={resetForm(null, null)} className="contactError">There was a problem... Please try again.</div>
        :
        <form onSubmit={ (e) => handleReCaptchaVerify(e, state)} className="contactForm">
            <Input dispatch={dispatch} value={state.name} name="name"/>
            <Input dispatch={dispatch} value={state.email} name="email"/>
            <Input dispatch={dispatch} value={state.subject} name="subject"/>
            <Textarea dispatch={dispatch} value={state.message} name="message"/>
            <button>submit</button>
        </form>
    )
}