import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useEvents } from '../../pages/api'
import dayjs from 'dayjs'

export const UpcomingEvents = () => {
    const [ events, getEvents ] = useEvents(null)

    useEffect(() => {
        getEvents()
    }, [])

    const parsedEvents = events && events && events.map((event, key) => {
        let eventDate = dayjs.unix(event.expirationDate).format('dddd, MMMM D, YYYY h:mm A')
        return(
            <Link to={`/createit/${encodeURIComponent(event.slug)}`} className="eventExcerpt" key={key}>
                <img srcSet={event.featuredImage.node.srcSet}/>
                <div className="eventExcerptText">
                    <p>{event.title}</p>
                    <span>{eventDate}</span>
                </div>
            </Link>
        )
    })
    
    return(
        events === false ?
        <></>
        :
        <>
            <h3>Upcoming Events</h3>
            <div className="upcomingEvents">
                {parsedEvents}
            </div>
        </>
    )
}