import { useEffect, useState, useContext } from 'react'
import { useEvent } from '../../pages/api'
import parse from 'html-react-parser'
import dayjs from 'dayjs'
import { Square } from './'

export const SingleEvent = ({ postSlug }) => {

    const [quantity, setQuantity ] = useState(1)

    const defaultEventState = {
        expirationDate: "",
        postId: null,
        content: "",
        price: null,
        square: "",
        title: ""
    }
    const [ event, getEvent, updateInventory ] = useEvent(defaultEventState)
    const { content, ...rest } = event

    useEffect(() => {
        if(postSlug) {
            getEvent(postSlug)
        }
    }, [postSlug, quantity])

    return(
        event && parse(content, {
            replace: domNode => {
                if(domNode.attribs && domNode.attribs.id === 'eventPayPal') {
                    return (
                        <>
                        <Square squareURL={event.square}/>
                        </>
                    )
                }
            }
        })
    )
}