import { useContext, useState } from "react"
import { ModalContext } from "../../context"
import { Modal } from '../modal'

export const Gallery = (children) => {

    const modal = useContext(ModalContext)
    const { isOpen, modalName } = modal.modal

    const [ imgSrc, setImgSrc ] = useState(null)

    const openImage = (event) => {
        setImgSrc(event.target.src)
        modal.openModal('full-image')
    }

    const gallery = children.domNode

    return (
        <>
            {
            gallery.map((child, i) => {
                if(child.type === "tag") {
                    return(
                        <div onClick={(event) => openImage(event)} key={i}>
                            <img src={child.children[0].attribs.src}/>
                        </div>
                    )
                }
            })
            }
            {
            isOpen === true && modalName == 'full-image'?
            <Modal>
                <div className="imageModal">
                    <img src={imgSrc}/>
                    <span onClick={() => modal.closeModal()} className="material-symbols-outlined">close</span>
                </div>
            </Modal>
            :
            null
            }
        </>
    )
}