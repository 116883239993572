import { ModalContext } from "../context"
import { useState } from 'react'

export const ModalProvider = ({ children }) => {

    const [ modal, setIsOpen ] = useState({ isOpen: false, modalName: null })

    const openModal = (modalName) => {
        setIsOpen({ isOpen: true, modalName})    
    }

    const closeModal = () => {
        setIsOpen({ isOpen: false, modalName: null })
    }

    return(
        <ModalContext.Provider
            value={{ modal, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    )
}