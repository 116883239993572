import { SingleEvent } from '../components/group'
import { Layout } from '../components/layout'
import { useParams } from 'react-router-dom'

export const Event = () => {

    const { slug } = useParams()

    return(
        <Layout>
            <div className="page event">
                <SingleEvent postSlug={slug}/>
            </div>
        </Layout>
    )
}