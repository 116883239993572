import { useEffect } from "react"
import { usePage } from "./api"
import parse from "html-react-parser"
import { Layout } from '../components/layout'

export const Kids = () => {

    const [ page, getPage ] = usePage(null)

    useEffect(() => {
        getPage("kids")
    }, [])

    return(
        <Layout>
            <div className="page kids">
                {page ? parse(page) : null}
            </div>
        </Layout>
    )
}