import { useEffect } from 'react'
import { Layout } from '../components/layout'
import { UpcomingEvents } from '../components/group'
import { usePage } from './api'
import parse from 'html-react-parser'

export const  Home = (props) => {

  const [ page, getPage ] = usePage(null)

  useEffect(() => {
    getPage("home")
  }, [])

  return (
    <Layout>
      <div className="page home">
        {page && parse(page, {
          replace: domNode => {
            if(domNode.attribs && domNode.attribs.id === 'homeEventsBlock') {
                return <UpcomingEvents/>
            }
          }
        })}
      </div>
    </Layout>
  )
}
