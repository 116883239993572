import { useState, useRef } from 'react'
import { ModalProvider } from '../../providers'
import { MainHeader, MainFooter, MainNav } from '../group'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Layout = ({ children }) => {

    const mainNavRef = useRef()

    const [ isOpen, setIsOpen ] = useState(false)

    const toggleNav = () => {
        setIsOpen(!isOpen)
    }

    return(
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            container={{parameters: { badge: 'inline'}}}
        >
            <ModalProvider>
                <div className={`allContent${isOpen ? " showMainNav" : ""}`}
                    style={{"marginLeft": isOpen ? "-" + mainNavRef.current.offsetWidth + "px" : "0px"}}>
                    <MainHeader isOpen={isOpen} toggleNav={toggleNav}/>
                    <MainNav ref={mainNavRef} isOpen={isOpen}  toggleNav={toggleNav}/>
                    {children}
                    <MainFooter/>
                </div>
            </ModalProvider>
        </GoogleReCaptchaProvider>
    )
}