import { useState } from 'react'

export const useNewsletter = (defaultMessageState) => {
    const [ messageState, setMessageState ] = useState(defaultMessageState)

    const sendRequest = (e, email) => {
        e.preventDefault()
        fetch(`${process.env.REACT_APP_NEWSLETTER}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(email)
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            let message = data.message.errors ? data.message.errors[0].message : "Success!"
            setMessageState({ email: "", response: message })
        })
    }

    return [ messageState, sendRequest ]
}