import { useState } from 'react'

export const usePage = () => {
  const [ page, setPage ] = useState(null)



  const getPage = (pageName) => {
    fetch(process.env.REACT_APP_WP_API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `{
            pageBy(uri: "${pageName}") {
                content
            }
        }`,
      }),
    })
    .then(res => res.json())
    .then((res) => {
      setPage(res.data.pageBy.content)
    })
  }
  return [ page, getPage ]
}
