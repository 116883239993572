import { Link } from 'react-router-dom'
import createItLogo from '../../images/createItLogo.svg'

export const MainHeader = ({ isOpen, toggleNav}) => {
    return(
        <header className="mainHeader">
            <Link className="mainLogo" to="/">
                <img src={createItLogo} alt="Create It in Donelson"/>
            </Link>
                <span onClick={() => toggleNav()} className="mainNavControl material-symbols-outlined">{`${isOpen ? 'close' : 'menu'}`}</span>
        </header>
    )
}