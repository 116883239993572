export const inputReducer = ( state, action ) => {
   
    switch ( action.type ) {
        case 'change':
            return { 
                ...state,
                [action.name]: action.value
            }
        case 'reset':
            return action.value
        default: 
            return state
    }
}