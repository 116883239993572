import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useEvents } from '../../pages/api'
import dayjs from 'dayjs'

export const EventList = () => {
    const [ events, getEvents ] = useEvents(null)

    useEffect(() => {
        getEvents(true)
    }, [])

    const parsedEvents = events && events.map((event, key) => {
        let eventDate = dayjs.unix(event.expirationDate).format('dddd, MMMM D, YYYY h:mm A')
        return(
            <Link className="eventExcerpt" to={`/createit/${event.slug}`} key={key}>
                <img srcSet={event.featuredImage.node.srcSet}/>
                <div className="eventExcerptText">
                    <p>{event.title}</p>
                    <span>{eventDate}</span>
                </div>
            </Link>
        )
    })
    
    return(
        events === false ?
        <></>
        :
        <>
            <div className="eventList">
                {parsedEvents}
            </div>
        </>
    )
}