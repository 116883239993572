import { useState } from 'react'
import dayjs from 'dayjs'

export const useEvent = (defaultEventState) => {
  const [ event, setEvent ] = useState(defaultEventState)

  const getEvent = (postSlug) => {
    console.log(postSlug)
    if(postSlug !== undefined) {
      fetch(process.env.REACT_APP_WP_API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `{
            postBy(slug: "${postSlug}") {
              content
              price
              square
              postId
              title
              expirationDate
            }
          }`,
        }),
      })
      .then(res => res.json())
      .then((res) => {
        let { postId, price, square, content, title, expirationDate } = res.data.postBy
        // Use postId to fetch available inventory
        fetch(process.env.REACT_APP_INVENTORY, {
          method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postId)
        })
        .then((res) => {
          return res.json()
        })
        .then(data => {
          let totalSales = data.total_sales
          setEvent({ postId, price, square, content, title, expirationDate, totalSales })
        })
      })
    }    
  }

  const updateInventory = (details, postId, event_name, event_date) => {
    
    const inventoryDetails = {
      name: `${details.payer.name.given_name} ${details.payer.name.surname}`,
      quantity: details.purchase_units[0].items[0].quantity,
      postId: postId,
      event_date: event_date,
      event_name: event_name
    }

    fetch(`${process.env.NEXT_PUBLIC_UPDATE_INVENTORY}`, {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(inventoryDetails)
    })
    .then(res => res.json())    
    .then(data => {
      
    })
  }

  return [ event, getEvent, updateInventory ]
}
