import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const Modal = ({ children }) => {
    
    const [ mounted, setMounted ] = useState(false)

    useEffect(() => {
        setMounted(true)
        return () => {
            setMounted(false)
        }
    },[])

    return mounted ? createPortal(
        <div className="modalScrim">
            {children}        
        </div>, document.querySelector("#modal-root")) : null
}