import { forwardRef, useContext } from 'react'
import { ModalContext } from '../../context'
import { Modal } from '../modal'
import { Newsletter } from './'
import { Link, NavLink } from 'react-router-dom'

// eslint-disable-next-line react/display-name
export const MainNav = forwardRef((props, ref) => {

    const modal = useContext(ModalContext)
    const { isOpen, modalName } = modal.modal

    const handleSuccess = () => {
        setTimeout(function() {
            if(isOpen) {
                modal.closeModal()
            }
        }, 3000)
    }

    return(
         <nav ref={ref} className="mainNav">
            <ul>
                <li>
                    <NavLink className="nav-item nav-link" to="/about">
                        about
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-item nav-link" to="/events">
                        events
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-item nav-link" to="/private-parties">
                        private parties
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-item nav-link" to="/kids">
                        kids
                    </NavLink> 
                </li>
                <li>
                    <NavLink className="nav-item nav-link" to="/contact">
                        contact
                    </NavLink>
                </li>
                <li>
                    <button onClick={(e) => modal.openModal("simple-newsletter")} className="mainOpenNewsleter">Get our Newsletter</button>
                </li>
            </ul>
            <Newsletter handleSuccess={handleSuccess} isMainNewsletter={true}/>
            {
                isOpen === true && modalName == "simple-newsletter" ?
                <Modal modal={"simple"}>
                    <div className="newsletterModal">
                        <Newsletter handleSuccess={handleSuccess}>
                            <button className="newsletterCancel" onClick={() => modal.closeModal()}>Cancel</button>
                        </Newsletter>
                    </div>
                </Modal>
                :
                null
            }
        </nav>
    )
})