export const Square = ({ squareURL }) => {
    console.log(squareURL)
    return(
        <div className="squareContainer">
            <div className="squareButton">
                <a target="_blank" className="squareLink" href={squareURL} 
                >Reserve Seat</a>
            </div>
        </div>
    )
}
