import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home, About, PrivateParties, Events, Kids, Contact, Event, Createit } from './pages'
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="private-parties" element={<PrivateParties/>}/>
        <Route path="events" element={<Events/>}/>
        <Route path="kids" element={<Kids/>}/>
        <Route path="contact" element={<Contact/>}/>
        <Route path="createit/" element={<Createit/>}>
          <Route path=":slug" element={<Event/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
