import { useEffect } from 'react'
import { Layout } from '../components/layout'
import { EventList } from '../components/group'
import { usePage } from './api'
import parse from 'html-react-parser'

export const Events = () => {

    const [ page, getPage ] = usePage(null)

  useEffect(() => {
    getPage("events")
  }, [])

    return(
        <Layout>
            <div className="page events">
                <EventList/>
                {page && parse(page)}
            </div>
        </Layout>
    )
}