import { useReducer } from 'react'
import { inputReducer } from '../../reducers'
import { EmailInput } from './'
import { useNewsletter } from '../../pages/api'

export const Newsletter = ({ children = null, isMainNewsletter = false, handleSuccess = null }) => {

    const defaultState = {
        email: ""
    }

    const defaultRequestState = {
        email: "",
        response: null
    }

    const [ state, dispatch ] = useReducer(inputReducer, defaultState)
    const [ messageState, sendRequest ] = useNewsletter(defaultRequestState)

    return(
        <form onSubmit={(e) => sendRequest(e, state.email)} className={`newsletter${isMainNewsletter ? " main" : ""}`}>
            <EmailInput placeholder="pablopicasso@gmail.com" label="Sign up for our newsletter" name="email" value={state.email} dispatch={dispatch}/>
            { children && children }
            <button>Sign Up</button>
            {
                messageState.response ?
                <div className="newsletterErrors">
                    {messageState.response === "Success!" ?
                    <span onLoad={handleSuccess()} className="success">{messageState.response}</span>
                    :
                    <span className="error">{messageState.response}</span>
                    }
                </div>
                : null
            }
        </form>
    )
}