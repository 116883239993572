import { Link } from 'react-router-dom'

export const MainFooter = () => {
    return(
        <footer className="mainFooter">
            <nav className="footerNav">
                <ul>
                    <li>
                        <Link to="/about">
                            about
                        </Link>
                    </li>
                    <li>
                        <Link to="/events">
                            events
                        </Link>
                    </li>
                    <li>
                        <Link to="/private-parties">
                            private parties
                        </Link>
                    </li>
                    <li>
                        <Link to="/kids">
                            kids
                        </Link> 
                    </li>
                    <li>
                        <Link to="/contact">
                            contact
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="footerAddress">
                <span>Create&nbsp;It&nbsp;in&nbsp;Donelson is&nbsp;brought&nbsp;to&nbsp;you&nbsp;by:<br/></span>
                <a href="https://robinsgallery.com">Robin&apos;s&nbsp;Gallery and&nbsp;Custom&nbsp;Frames</a><br/>
                <span>2602 Lebanon Pike</span><br/>
                <span>Nashville, TN 37214</span><br/>
                <span>615-885-1694</span>
            </div>
        </footer>
    )
}