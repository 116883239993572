import { useEffect } from "react"
import { Layout } from '../components/layout'
import { usePage } from "./api"
import parse from 'html-react-parser'
import { Gallery } from '../components/group'

export const PrivateParties = () => {

    const [ page, getPage ] = usePage(null)

    useEffect(() => {
        getPage("private-parties")
    }, [])
    
    return(
        <Layout>
        <div className="page privateParties">
            {page && parse(page, {
                replace: domNode => {
                    if(domNode.attribs && domNode.attribs.id === 'privatePartiesGallery') {
                        return (<div className="privatePartiesGallery"><Gallery domNode={domNode.children}/></div>)
                    }
                }
            })}
        </div>
    </Layout>        
    )
}